import React from "react";
import { Container, Breadcrumb, Row, Col, Button} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Section, Post } from "../../components/Core";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';
import ReactStars from "react-rating-stars-component";
import DataTable from '../../components/data/banque/avisbanque'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons'

function avis() {

	const banque = 'Monabanq'
	const go = `/go/?go=${banque}`
	
	const star1 = {size: 22,value: 4.2,color: "grey",activeColor: "#ffc107",edit: false,};
	const star2 = {size: 22,value: 4.1,color: "grey",activeColor: "#ffc107",edit: false,};
	const star3 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,};
	const star4 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,};
	const star5 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,};
	const star6 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,}; 
	

    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Avis Monabanq : à lire absolument avant d'ouvrir un compte</title>
                <meta name="description" content="
              Cette banque est-elle réellement gratuite ? Avis détaillé de la banque Monabanq avec notations, présentation des services et tous les frais bancaires." />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item> <Link to={`/banque/`} style={{color: 'black'}}>Avis Banques</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'>Avis Monabanq</Breadcrumb.Item>
      	 </Breadcrumb>

	
	<Row>
		<Col sm={9}>  <h1 id='avish1' style={{color: 'rgb(41, 49, 61)'}}>Avis Monabanq</h1>
          <h2 id='avish2' style={{marginLeft: 0, marginBottom: 25, color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Monabanq propose aux particuliers une gamme complète de produits bancaires sans aucune condition de revenus. La banque a été « élu service client de l’année » en 2019. Notre avis monabanq :</h2>
	
 <a href={go} target='_blank' rel="noreferrer">
                <Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', borderColor: 'rgb(240 0 87)', color: 'white'}}>Voir l'offre <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
                </a>

		  
		  </Col>
	
		<Col sm={3} id='logoAvis'>
		<a href={go} target='_blank' rel="noreferrer">
		<StaticImage
		  	src='../../images/monabanq-logo.png'
			width={250}
			alt='logo monabanq'
			placeholder='tracedSVG'
			/>
		</a>
		</Col>
	</Row>

		 
<hr></hr>
<Row>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Compte bancaire</p></Col>
			<Col> <ReactStars {...star1} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Carte bancaire</p></Col>
			<Col><ReactStars {...star2} /></Col>
		</Row>
	</Col>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Tarifs</p></Col>
			<Col><ReactStars {...star3} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Application</p></Col>
			<Col><ReactStars {...star4} /></Col>
		</Row>
	</Col>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Comptabilité</p></Col>
			<Col><ReactStars {...star5} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Gestion</p></Col>
			<Col><ReactStars {...star6} /></Col>
		</Row>
	</Col>
</Row>
<hr></hr>
<Post>



<h2>Offre Monabanq</h2>
<p>L’offre de bienvenue de Monabanq consiste en une prime de 120€ pour toute première ouverture de compte. Vous aurez 2 choix :


</p><ul>
	<li>encaisser ce montant directement sur votre compte (1)</li>
	<li>verser le bonus à SOS village enfants (2)</li>

</ul>




<iframe id='videoYT'src="https://www.youtube.com/embed/XM7X_3Xb0xQ" title="avis Monabanq" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

<h2>Monabanq et l’association SOS villages enfants</h2>
<p>(2) Si vous choisissez de verser votre bonus à SOS village enfants, ce versement sera classé comme un don et vous permettra d’obtenir une réduction d’impôt à hauteur de 75% du montant.
</p><p>
L’association SOS villages enfants se concentre sur 4 piliers :
</p><ul>
	<li>la construction de maisons familiales pour tenter de recréer le noyau familial</li>
	<li>l’accompagnement de jeunes majeurs en difficultés</li>
	<li>la création d’un espace d’accueil d’urgence pour les enfants en danger immédiat</li>
	<li>Donner aux enfants la possibilité de rester enfant avec l’aménagement de leur chambre</li>
</ul>



<hr></hr>

<h2>Cartes bancaires Monabanq</h2>

<p>La banque met en avant les garanties et les assurances liées à ses différentes cartes bancaires.</p>

<ul>
	<li>La carte Visa Welcome Classic gratuite sous réserve d’une opération constaté (sinon 5€/mois).</li>
	<li>Les cartes Visa Premier et Ultim gratuites sous réserve d’une opération constaté par mois (sinon 15€/mois).</li>
	<li>Aucune condition d’opérations pour la carte Visa Classic.</li>
</ul>


<div style={{marginLeft: -5}}>
<DataTable row={[3,4,5,6,7]} what='cartesbancaires' id='postTable'/>
<DataTable row={[3,4,5,6,7]} what='cartesbancaires' id='mobileTable'/>

</div>

<h3>Carte Visa</h3>
<p>Les services gratuits de la carte Visa sont :</p>
<ul>
	<li>Assurance décès et invalidité</li>
	<li>Assurance maladie internationale</li>

</ul>
<h3>Carte Visa Premier</h3>
<p>Les services gratuits de la carte Visa Premier sont :</p>
<ul>
<li>Assurance décès et invalidité</li>
<li>Assurance maladie internationale</li>
<li>Garantie véhicule de location</li>
<li>Prise en charge des frais médicaux à l’étranger</li>
<li>Assistance rapatriement</li>
<li>Garantie neige et montagne</li>
<li>Garantie voyage</li>
</ul>




<div style={{maxWidth: 800, margin: 'auto'}}>
			<StaticImage
		  	src='../../images/monabanq-site.png'
			width={800}
			alt='site monabanq'
			placeholder='tracedSVG'
			id='pickky'
			/>
			</div>




			<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
			<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Monabanq <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>

	<h2>Carte à autorisation systématique (carte prépayée)</h2>
	<p>
Monabanq propose aussi une carte prépayé. Vous aurez un contrôle total sur votre budget. Plus aucune mauvaise surprise, lors de chaque transaction, Monabanq vérifiera la disponibilité des fonds sur votre compte courant.
</p>
<h3>
Option premium – l’offre VIP de Monabanq</h3>
<p>
Les découverts autorisés sont gratuits si les intérêts sont inférieurs à 3€ / trimestre. Au delà, votre découvert autorisé vous coutera un taux nominal de 8%/an.
</p><p>
L’option premium fini compléter l’offre assurance de Monabanq :
</p>
<ul>
	<li>Assurance bonne fin de livraison d’un achat sur Internet</li>
	<li>Couverture perte et vol de vos moyens de paiement</li>
	<li>Assurance vol de votre téléphone</li>
</ul>

<p>
Vous pourrez aussi partir en vacance en toute tranquilité et sans frais. Avec l’option premium, Monabanq vou offre les 50 premiers paiements ainsi que les premiers 25 retraits d’espèces par carte bancaire par an, partout dans le monde
</p>

<h3>Le chéquier Monabanq</h3>
<p>
Monabanq propose un chéquier gratuit. La banque en ligne a l’avantage de faire partie du groupe crédit mutuel. Vous pourrez donc déposer vos chèques dans les 2000 guichets automatiques du CIC.
</p><p>
La commande du chéquier est très simple et se fait en un clic de votre compte en ligne. Chez Monabanq, les chèques de banque sont aussi gratuits.
</p>
<h3>
Une banque en ligne avec guichet automatique</h3>
<p>
Chez Monabanq, la banque en ligne ne veut pas forcément dire 100% internet. Profitant de sa situation au centre du group Crédit Mutuel, vous pouvez utiliser ses 2000 guichets CIC pour effectuer les opérations suivantes :
</p>
<ul>
	<li>effectuer des virements</li>
	<li>imprimer un RIB</li>
	<li>consulter ses comptes</li>
	<li>changer le code secret de sa carte bancaire</li>
</ul>




<hr></hr>

<h2>Frais Bancaire Avis Boursorama Banque</h2>
<h3>Virements sortants</h3>
<DataTable row={[3,4,5,6,7]} what='virementSortants' id='postTable'/>
<DataTable row={[3,4,5,6,7]} what='virementSortants' id='mobileTable'/>

<h3>Virements entrants</h3>
<DataTable row={[3,4,5,6,7]} what='virementEntrants' id='postTable'/>
<DataTable row={[3,4,5,6,7]} what='virementEntrants' id='mobileTable'/>

<h3>Découverts</h3>
<DataTable row={[3,4,5,6,7]} what='decouverts' id='postTable'/>
<DataTable row={[3,4,5,6,7]} what='decouverts' id='mobileTable'/>

<h3>Irrégularités et incidents</h3>
<DataTable row={[3,4,5,6,7]} what='irregularites' id='postTable'/>
<DataTable row={[3,4,5,6,7]} what='irregularites' id='mobileTable'/>

<h2>Monabanq Pour Les Voyageurs</h2>
<h3>Retraits et paiements à l’étranger</h3>

<DataTable row={[3,4,5,6,7]} what='retraits' id='postTable'/>
<DataTable row={[3,4,5,6,7]} what='retraits' id='mobileTable'/>

<h3>Assurances</h3>
<DataTable row={[3,4,5,6,7]} what='assurances' id='postTable'/>
<DataTable row={[3,4,5,6,7]} what='assurances' id='mobileTable'/>


<h2>Service client Monabanq</h2>
<p>Vous pourrez joindre Monabanq durant ses heures d’ouverture du Lundi au Vendredi de 8h à 21h et le Samedi de 8h à 16h.</p>

<p>
Email et chat<br></br>
Telephone : 0810 002 001 (0.06€/min) + prix de l’appel<br></br>
Twitter : https://twitter.com/monabanq<br></br>
Facebook : https://www.facebook.com/Monabanq<br></br>
</p>

<h2>Avis Monabanq</h2>
<p>
Monabanq diffuse sur son site une note moyenne de 4.2/ 5 récolté sur plus de 3179 avis. Ces avis ont été certifiés par Critizr qui garantit leur authenticité.
</p><p>
On notera aussi que 9 clients Monabanq sur 10 recommande le service.
</p><p>
La tranquillité d’esprit et un accès à un panel de services complet à un prix. Monabanq n’est pas la banque en ligne la moins chère mais elle offre des services en compétition directe avec une banque traditionnelle.
</p>
<p>
App store : 4.5 / 5<br></br>
Google play : 4.4 / 5
</p>

<h3>Avantages</h3>
<ul>
	<li>Élu service client de l’année catégorie “banque en ligne"</li>
	<li>Meilleure offre de bienvenue du marché</li>
	<li>Contact humain : coaching budget, déclaration d’impôts</li>
	<li>Grand nombre de garanties incluses</li>
	<li>Accès aux guichets du CIC pour les dépôts de chèques</li>
	<li>Épargne d’une banque traditionnelles (Livret A et LDD)</li>
	<li>Offre premium adapté aux vacances à l’étranger</li>
	<li>Offre facilité de caisse rare chez les banques en ligne</li>
</ul>

<h3>Inconvénients</h3>

<ul>
	<li>La tranquilité d'esprit à un prix</li>
</ul>

<h2>Condition d’ouverture d’un compte monabanq</h2>
<p>Monabanq est une des seules banques en ligne qui permet d’ouvrir un compte sans conditions de revenus. Elle permet donc d’ouvrir un compte peu importe votre statut professionnel :</p>

<ul>
	<li>Employés</li>
	<li>Cadres</li>
	<li>Intérimaires</li>
	<li>Personnes en recherche d’emploi</li>
	<li>Retraités</li>
	<li>Intermittents etc</li>
</ul>

<p>Monabanq ne demande pas à ses clients d’opérer de versements réguliers sur ce compte. Vous n’avez donc pas besoin de domicilier votre salaire. Seule condition, verser la somme de 150€ lors de votre ouverture de compte.</p>



<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Je veux en savoir + <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>

<h2>Questions - Réponses sur Monabanq</h2>
	Q - Monabanq est-elle une banque en ligne ou une néobanque ?
	A - Monabanq est une véritable banque en ligne adossée au Groupe Crédit Mutuel.

	Q - Peut-on payer avec son mobile avec Apple pay sur Monabanq ?
	A - Malheureusement pas encore, mais nous imaginons que c’est “dans les tuyaux”!

	Q - Où sont situés les locaux de Monabanq ?
	A - Les bureaux de Monabanq sont au 61 Avenue Halley, 59650 Villeneuve-d'Ascq, France. Vous pouvez aussi utiliser les 2000 guichets sur CIC pour effectuer des opérations courantes.

<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Ouvrir un compte <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>

		</Post>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default avis
